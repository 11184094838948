import { createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode } from "vue";
import { mixins } from "@/plugins/mixins";
import RecordRepresentationDetail from "@/views/business/recordRepresentation/detail";
export default {
  name: "myTask",
  components: {
    RecordRepresentationDetail
  },
  mixins: [mixins],
  data() {
    return {
      recordDetailDrawer: false,
      carDetailDrawer: false,
      specialCarDrawer: false,
      businessKey: null,
      searchItems: [{
        label: '任务类型',
        prop: 'definitionKey',
        type: 'select',
        items: [{
          value: 'record_representation',
          label: '骑行记录申述'
        }],
        itemProp: {
          label: 'label',
          value: 'value'
        }
      }, {
        label: '用户电话',
        prop: 'customerPhone'
      }],
      columns: [{
        label: '任务类型',
        customRender: props => {
          if (props.row.processDefinitionKey === 'record_representation') {
            return _createVNode(_resolveComponent("el-tag"), null, {
              default: () => [_createTextVNode("\u9A91\u884C\u8BB0\u5F55\u7533\u8FF0")]
            });
          } else {
            return _createVNode(_resolveComponent("el-tag"), null, {
              default: () => [props.row.processDefinitionKey]
            });
          }
        }
      }, {
        label: '任务名称',
        prop: 'name'
      }, {
        label: '用户电话',
        prop: 'customerPhone'
      }, {
        label: '创建时间',
        prop: 'createTime'
      }],
      linkButtons: [{
        name: '指派',
        code: 'delegate_task',
        click: this.delegateTask,
        permissions: ['delegate_task']
      }, {
        // process_task
        name: '处理',
        code: 'process_task',
        click: this.processTask,
        permissions: ['process_task']
      }]
    };
  },
  methods: {
    delegateTask(row) {
      console.log(row);
    },
    processTask(row) {
      if (row.processDefinitionKey === 'record_representation') {
        this.recordDetailDrawer = true;
      }
      this.businessKey = row.businessKey;
    },
    recordAuditComplete() {
      this.recordDetailDrawer = false;
      this.$refs.tablePage.fetch();
    },
    carAuditComplete() {
      this.carDetailDrawer = false;
      this.$refs.tablePage.fetch();
    },
    specialCarAuditComplete() {
      this.specialCarDrawer = false;
      this.$refs.tablePage.fetch();
    }
  }
};